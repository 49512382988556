import React from "react";
import MainLayout from "../components/layoutmain";
import { StaticImage } from "gatsby-plugin-image";

export default function Approche() {
  const seoProps = {
    title: "Numeko - Notre approche",
    description:
      "Les grandes étapes de la réalisation de votre projet informatique. Notre promesse: construire un projet agile, fini et cohérent avec un coût maîtrisé - Échanges, Idéation & Conception, Réalisation & Développement, Points d'étape réguliers, Bilan de parcours, Mise en ligne, Maintenance / Évolutions",
  };

  return (
    <MainLayout seoProps={seoProps}>
      <div className={"bloc-main approche"}>
        <div className={"bloc-category bloc-presentation"}>
          <h1>NOTRE APPROCHE</h1>
          <hr />
          <h4>
            <span>Notre promesse</span> : construire un projet agile, fini et
            cohérent avec un coût maîtrisé
          </h4>
        </div>

        <div className={"bloc-category bloc-content emphase"}>
          <div>
            <p>
              Nous essayons au <span>maximum</span> de privilégier une{" "}
              <span>approche agile</span> et <span>compatible</span> avec vos
              contraintes de budget, l’agilité et l'éco-conception allant de
              pair.
            </p>
            <p>
              A l’issue de la phase de conception, nous obtenons une liste de
              tâches catégorisables qui nous permet d’évaluer un budget jours
              (théorique).
            </p>
            <p>
              Pour <span>répondre</span> à notre engagement de{" "}
              <span>transparence</span>, chaque itération représente des
              journées de travail qui sont décomptées de l'évaluation totale
              avec la possibilité de faire des ajustements et de changer de
              direction.
            </p>
            <p>
              Également, vous êtes régulièrement informés si une nouveauté
              implique de revoir d'autres fonctionnalités pour respecter le
              budget jours.
            </p>
          </div>
        </div>

        <div className={"bloc-category bloc-content"}>
          <h1>Les grandes étapes de la réalisation de votre projet</h1>
          <div className={"bloc-expertise"}>
            <div className={"description"}>
              <div className={"number"}>01</div>
              <h1>Échanges</h1>
              <div className={"content"}>
                Selon la <span>typologie</span> et les <span>contraintes</span>{" "}
                du projet, nous établissons des <span>ateliers d'échanges</span>{" "}
                pour analyser avec vous les premiers besoins fonctionnels et
                données pertinentes. <br />
                <br />
                Dans le cas d'un projet existant, un{" "}
                <span>audit d'éco-conception</span> est possible. Dans ce cas,
                nous auditons la globalité de l’application et de ses
                fonctionnalités. <br />
                Nous recensons les <span>mauvaises pratiques</span>, les axes{" "}
                <span>stratégiques d’améliorations</span> et déterminons des{" "}
                <span>indicateurs</span> qui permettent de <span>suivre</span>{" "}
                l’impact environnemental du service sur le long terme.
              </div>
            </div>
            <div className={"image"}>
              <StaticImage
                src={"../images/approche_condensed.svg"}
                alt={
                  "Groupe de personne dans un atelier de travail pour concevoir une application web"
                }
                placeholder={"none"}
                quality={90}
                width={600}
              />
            </div>
          </div>

          <div className={"bloc-expertise"}>
            <div className={"description"}>
              <div className={"number"}>02</div>
              <h1>Idéation & Conception</h1>
              <div className={"content"}>
                <span>Inventorier</span> l’ensemble des fonctionnalités{" "}
                <span>utiles</span> au projet tout en intégrant les impacts{" "}
                <span>environnementaux</span>, <span>sociaux</span> et{" "}
                <span>économiques</span>. Cela permet de réaliser différents
                types de livrables fonctionnels, designs et techniques :
                <ul>
                  <li>Prototype graphique</li>
                  <li>Cahier d’architecture technique</li>
                  <li>Estimation du MVP</li>
                  <li>Planification des versions (Release)</li>
                  <li>Preuve de concept (POC)</li>
                </ul>
              </div>
            </div>
            <div className={"image"}>
              <StaticImage
                src={"../images/ux_ui_design.png"}
                alt={
                  "Personne dans une réflexion UX-UI design pour une application"
                }
                placeholder={"none"}
                quality={90}
                width={600}
              />
            </div>
          </div>

          <div className={"bloc-expertise"}>
            <div className={"description"}>
              <div className={"number"}>03</div>
              <h1>Réalisation & Développement</h1>
              <div className={"content"}>
                Après avoir <span>réalisé</span> les différentes documentations
                exhaustives qui détaillent les phases du projet, nous{" "}
                <span>construisons</span> l'<span>ergonomie</span> et l'
                <span>esthétique</span> adaptés à celui-ci tout en respectant
                les <span>bonnes pratiques</span> du moment :
                <ul>
                  <li>Direction artistique</li>
                  <li>Experience utilisateur (UX)</li>
                  <li>Esthétique des interfaces (UI)</li>
                  <li>Prototypes & Animations</li>
                </ul>
                Enfin, nous <span>réalisons</span> l'architecture technique de
                votre projet. Nous nous <span>inscrivons</span>, avec vous, dans
                une <span>démarche commune</span> orientée DevOps. Nos équipes
                mettent en œuvre l’<span>automatisation</span> des tâches et le{" "}
                <span>déploiement continu</span> de vos projets, permettant
                ainsi de <span>maintenir</span> plus <span>efficacement</span>{" "}
                votre patrimoine applicatif.
                <ul>
                  <li>Architecture & Devops</li>
                  <li>Base de données</li>
                  <li>API</li>
                  <li>Développements</li>
                  <li>Tests</li>
                </ul>
              </div>
            </div>
            <div className={"image"}>
              <StaticImage
                src={"../images/expertises_condensed.svg"}
                alt={"Développeur concevant une application"}
                placeholder={"none"}
                quality={90}
                width={600}
              />
            </div>
          </div>

          <div className={"bloc-expertise"}>
            <div className={"description"}>
              <div className={"number"}>04</div>
              <h1>Points d'étape réguliers</h1>
              <div className={"content"}>
                Comme votre projet est <span>déployé</span> de façon{" "}
                <span>continue</span>, alors vous pourrez <span>consulter</span>{" "}
                régulièrement nos <span>avancées</span>. Vous accédez à un
                environnement de préproduction dans lequel vous verrez arriver
                les <span>évolutions</span> du projet, vous pourrez{" "}
                <span>tester</span> les nouvelles fonctionnalités et donner
                directement vos <span>retours</span>.
              </div>
            </div>
            <div className={"image"}>
              <StaticImage
                src={"../images/approche_condensed.svg"}
                alt={
                  "Groupe de personne dans un atelier de travail pour concevoir une application web"
                }
                placeholder={"none"}
                quality={90}
                width={600}
              />
            </div>
          </div>

          <div className={"bloc-expertise"}>
            <div className={"description"}>
              <div className={"number"}>05</div>
              <h1>Bilan de parcours</h1>
              <div className={"content"}>
                Aux <span>moments clés</span> du projet, nous réalisons un{" "}
                <span>point d'ensemble</span>. L'objectif est de faire un{" "}
                <span>bilan</span> sur les travaux accomplis et, si nécessaire,
                d’effectuer des <span>arbitrages stratégiques</span> sur les
                tâches à venir. <br />À cette étape, il est possible d'
                <span>optimiser</span> et de faire <span>varier</span> le
                travail restant pour maintenir vos objectifs sans pour autant{" "}
                <span>augmenter le budget</span>.
              </div>
            </div>
            <div className={"image"}>
              <StaticImage
                src={"../images/approche_condensed.svg"}
                alt={
                  "Groupe de personne dans un atelier de travail pour concevoir une application web"
                }
                placeholder={"none"}
                quality={90}
                width={600}
              />
            </div>
          </div>

          <div className={"bloc-expertise"}>
            <div className={"description"}>
              <div className={"number"}>06</div>
              <h1>Mise en ligne</h1>
              <div className={"content"}>
                Vous <span>validez</span> la recette complète de l’application
                et notre équipe s’occupe de la mise en ligne du projet. Nous{" "}
                <span>déterminons</span> ensemble le temps où nous garantissons
                la correction des bugs sur votre projet (VSR).
              </div>
            </div>
            <div className={"image"}>
              <StaticImage
                src={"../images/mise_en_ligne.png"}
                alt={"Développeur mettant en ligne le projet d'un client"}
                placeholder={"none"}
                quality={90}
                width={600}
              />
            </div>
          </div>

          <div className={"bloc-expertise"}>
            <div className={"description"}>
              <div className={"number"}>07</div>
              <h1>Maintenance / Évolutions</h1>
              <div className={"content"}>
                Maintenant que votre projet est en ligne, nous pouvons continuer
                d’avancer ensemble de plusieurs manières :
                <ul>
                  <li>Mettre en place une maintenance (correction de bugs)</li>
                  <li>
                    Programmer de nouveaux cycles de développements (nouvelles
                    fonctionnalités)
                  </li>
                </ul>
              </div>
            </div>
            <div className={"image"}>
              <StaticImage
                src={"../images/maintenance.png"}
                alt={
                  "Développeur intervenant dans le cadre d'une maintenance d'une application"
                }
                placeholder={"none"}
                quality={90}
                width={600}
              />
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
